import axios from 'axios'

export const userLocation = async () => {
  try {
    const req = await axios.get('https://ipapi.co/json/')

    if (req.data)
      return {
        locationString: `${req.data.city}, ${req.data.region} - ${req.data.country} | Timezone: ${req.data.timezone} | IP:${req.data.ip} - http://ipinfo.io/${req.data.ip}`,
        ipAddress: req.data.ip,
      }

    return {
      locationString: 'Unknown',
      ipAddress: 'Unknown',
    }
  } catch (e) {
    return {
      locationString: 'Unknown',
      ipAddress: '0.0.0.0',
    }
  }
}
